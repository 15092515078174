import moment from 'moment';

import type { TrelloWindow } from '@trello/window-types';

declare const window: TrelloWindow;

import { makeYearAwareCalendar } from './makeYearAwareCalendar';

function processRelativeTime(
  num: any,
  withoutSuffix: any,
  key: any,
  isFuture: any,
) {
  const format = {
    m: ['eine Minute', 'einer Minute'],
    h: ['eine Stunde', 'einer Stunde'],
    d: ['ein Tag', 'einem Tag'],
    dd: [`${num} Tage`, `${num} Tagen`],
    M: ['ein Monat', 'einem Monat'],
    MM: [`${num} Monate`, `${num} Monaten`],
    y: ['ein Jahr', 'einem Jahr'],
    yy: [`${num} Jahre`, `${num} Jahren`],
  };

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return withoutSuffix ? format[key][0] : format[key][1];
}

moment.locale(window.locale, {
  months: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  monthsShort: [
    'Jan.',
    'Febr.',
    'Mrz.',
    'Apr.',
    'Mai',
    'Jun.',
    'Jul.',
    'Aug.',
    'Sept.',
    'Okt.',
    'Nov.',
    'Dez.',
  ],
  weekdays: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ],
  weekdaysShort: ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'],
  weekdaysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D. MMMM YYYY',
    LLL: 'D. MMMM YYYY LT',
    LLLL: 'D. MMMM',
  },
  // see `app/src/components/DateRangePicker/getLocalizedPlaceholder.ts`
  placeholder: {
    l: 'T.M.JJJJ',
    LT: null, // 'H:mm' or 'S:mm'? TODO https://trello.atlassian.net/browse/PANO-2026
  },
  calendar: makeYearAwareCalendar({
    sameDay: '[Heute um] LT [Uhr]',
    nextDay: '[Morgen um] LT [Uhr]',
    lastDay: '[Gestern um] LT [Uhr]',
    nextWeek: 'llll [um] LT [Uhr]',
    lastWeek: 'llll [um] LT [Uhr]',
    sameYear: 'llll [um] LT [Uhr]',
    sameElse: 'll [um] LT [Uhr]',
  }),
  relativeTime: {
    future: 'in %s',
    past: 'vor %s',
    s: 'ein paar Sekunden',
    m: processRelativeTime,
    mm: '%d Minuten',
    h: processRelativeTime,
    hh: '%d Stunden',
    d: processRelativeTime,
    dd: processRelativeTime,
    M: processRelativeTime,
    MM: processRelativeTime,
    y: processRelativeTime,
    yy: processRelativeTime,
  },
  ordinalParse: /\d{1,2}\./,
  ordinal: () => '%d.',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
});
